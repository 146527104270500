import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyKatieCouricTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="Katie Couric"
        description="Hook Points grabs new audiences for a nationally recognized broadcast journalist"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              Created a <span>highly unique and innovative strategy</span> to
              help Katie adapt and expand beyond traditional television to
              digital through her podcast
            </li>
            <li>
              We used data and testing to develop Hook Points that evoked{" "}
              <span>strong emotional response</span> among online audiences to
              drive interest in podcast episodes and guests
            </li>
            <li>
              Resulted in <span>1 billion</span> global platform views,{" "}
              <span>150 million</span> social media views,{" "}
              <span>200% increase in social shares,</span> and saved tens of
              millions of dollars in traffic-acquisition costs while helping to
              establish Katie’s brand across digital and social platforms
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-michael-breus"}
        text="If you’d like to hear more about how we helped Katie Couric,"
        subText=" get to know us"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyKatieCouricTemplate
